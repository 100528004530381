import * as LabelPrimitive from '@radix-ui/react-label'
import * as React from 'react'
import { cn } from '../../lib/utils'

const Label = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;
  return (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(
        'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
        className
      )}
      {...otherProps}
    />
  )
})

Label.displayName = LabelPrimitive.Root.displayName

export { Label }
