// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDMZ36FpePm0QqI1RznuYpDlazwHyOPhZU",
    authDomain: "database-bc9ef.firebaseapp.com",
    projectId: "database-bc9ef",
    storageBucket: "database-bc9ef.appspot.com",
    messagingSenderId: "653614384154",
    appId: "1:653614384154:web:2a0a4f6e60a3410fe4bda3",
    measurementId: "G-RQZ02M5W53"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const auth = getAuth(app);

export { auth , db};
