import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useParams, useNavigate , useHistory } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

import AddClientButton from '../components/functions/addClient';

import { fabric } from 'fabric';

import { pdfjsLib3 } from './pdfjs3-init';

//import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import axios from 'axios';

const AgencyClients = () => {
  const { agencyName } = useParams();
  const navigate = useNavigate();
  const [clients, setClients] = useState(['Client 1', 'Client 2', 'Client 3']);
  const [selectedClient, setSelectedClient] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isSnippetMode, setIsSnippetMode] = useState(false);
  const [fabricCanvases, setFabricCanvases] = useState([]);
  const [pdfCanvases, setPdfCanvases] = useState([]);
  const fileInputRef = useRef(null);
  const pdfViewerRef = useRef(null);
  const fabricCanvasesRef = useRef([]);
  const pdfCanvasesRef = useRef([]);
  

  const handleClientClick = (client) => {
    setSelectedClient(client);
    navigate(`/agency/${agencyName}/client/${client}`)
  };

  const handleAddClient = (newClientName) => {
    setClients([...clients, newClientName]);
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('document', file);
      try {
        const response = await axios.post('http://localhost:5000/process', formData, { responseType: 'blob' });
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
        renderPDFForSnippeting(url);
      } catch (error) {
        console.error('Processing failed:', error);
      }
    }
  };

  const renderPDFForSnippeting = async (url) => {
    const arrayBuffer = await fetch(url).then(res => res.arrayBuffer());
    const pdf = await pdfjsLib3.getDocument({ data: arrayBuffer }).promise;
    pdfViewerRef.current.innerHTML = '';
    const newFabricCanvases = [];
    const newPdfCanvases = [];

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });
      const pageDiv = document.createElement('div');
      pageDiv.className = 'pdf-page';
      pageDiv.style.position = 'relative';
      pageDiv.style.width = `${viewport.width}px`;
      pageDiv.style.height = `${viewport.height}px`;

      const pdfCanvas = document.createElement('canvas');
      const pdfContext = pdfCanvas.getContext('2d');
      pdfCanvas.height = viewport.height;
      pdfCanvas.width = viewport.width;
      const renderContext = { canvasContext: pdfContext, viewport: viewport };
      await page.render(renderContext);
      pageDiv.appendChild(pdfCanvas);
      newPdfCanvases.push(pdfCanvas);

      const fabricCanvas = new fabric.Canvas(document.createElement('canvas'));
      fabricCanvas.setWidth(viewport.width);
      fabricCanvas.setHeight(viewport.height);
      fabricCanvas.setDimensions({ width: `${viewport.width}px`, height: `${viewport.height}px` }, { cssOnly: true });
      fabricCanvas.wrapperEl.className = 'fabric-canvas';
      fabricCanvas.wrapperEl.style.position = 'absolute';
      fabricCanvas.wrapperEl.style.top = '0';
      fabricCanvas.wrapperEl.style.left = '0';
      pageDiv.appendChild(fabricCanvas.wrapperEl);
      newFabricCanvases.push(fabricCanvas);

      pdfViewerRef.current.appendChild(pageDiv);
    }

    fabricCanvasesRef.current = newFabricCanvases;
    pdfCanvasesRef.current = newPdfCanvases;
    setFabricCanvases(newFabricCanvases);
    setPdfCanvases(newPdfCanvases);
  };

  const toggleSnippetMode = useCallback(() => {
    setIsSnippetMode(prevMode => !prevMode);
  }, []);

  useEffect(() => {
    const pdfContainer = pdfViewerRef.current;
    if (pdfContainer) {
      pdfContainer.addEventListener('scroll', handleScroll);
      return () => pdfContainer.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScroll = () => {
    const scrollTop = pdfViewerRef.current.scrollTop;
    fabricCanvasesRef.current.forEach((canvas, index) => {
      const fabricWrapper = canvas.wrapperEl;
      fabricWrapper.style.top = `${scrollTop}px`;
    });
  };

  useEffect(() => {
    if (isSnippetMode) {
      fabricCanvasesRef.current.forEach(canvas => {
        let rect, isDown, origX, origY;
        canvas.on('mouse:down', (o) => {
          isDown = true;
          const pointer = canvas.getPointer(o.e);
          const scrollTop = pdfViewerRef.current.scrollTop;
          origX = pointer.x;
          origY = pointer.y + scrollTop;
          rect = new fabric.Rect({
            left: origX,
            top: origY - scrollTop,
            width: 0,
            height: 0,
            fill: 'rgba(255,0,0,0.1)',
            stroke: 'red',
            strokeWidth: 2,
            selectable: true,
            evented: true
          });
          canvas.add(rect);
        });
        canvas.on('mouse:move', (o) => {
          if (!isDown) return;
          const pointer = canvas.getPointer(o.e);
          const scrollTop = pdfViewerRef.current.scrollTop;
          rect.set({
            width: Math.abs(origX - pointer.x),
            height: Math.abs(origY - (pointer.y + scrollTop)),
            left: Math.min(origX, pointer.x),
            top: Math.min(origY, pointer.y + scrollTop) - scrollTop
          });
          canvas.renderAll();
        });
        canvas.on('mouse:up', () => {
          isDown = false;
          rect.setCoords();
        });
      });
    } else {
      fabricCanvasesRef.current.forEach(canvas => {
        canvas.off('mouse:down');
        canvas.off('mouse:move');
        canvas.off('mouse:up');
      });
    }
  }, [isSnippetMode]);

  const saveSnippets = useCallback(async () => {
    const snippets = [];
    fabricCanvasesRef.current.forEach((canvas, index) => {
      const pageSnippets = canvas.getObjects().filter(obj => obj.type === 'rect');
      const scrollTop = pdfViewerRef.current.scrollTop;
      pageSnippets.forEach((snippet, snippetIndex) => {
        const snippetCanvas = document.createElement('canvas');
        snippetCanvas.width = snippet.width;
        snippetCanvas.height = snippet.height;
        const snippetContext = snippetCanvas.getContext('2d');
        snippetContext.drawImage(
          pdfCanvasesRef.current[index],
          snippet.left,
          snippet.top + scrollTop,
          snippet.width,
          snippet.height,
          0,
          0,
          snippet.width,
          snippet.height
        );
        snippets.push({
          image: snippetCanvas.toDataURL("image/png"),
          name: `snippet_page${index + 1}_${snippetIndex + 1}.png`
        });
      });
    });

    try {
      const formData = new FormData();
      formData.append('pdf', await fetch(pdfUrl).then(res => res.blob()), 'document.pdf');
      snippets.forEach((snippet, index) => {
        formData.append(`snippet_${index}`, dataURItoBlob(snippet.image), snippet.name);
      });
      const response = await axios.post('http://localhost:5001/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('Upload response:', response.data);
    } catch (error) {
      console.error('Upload failed:', error);
    }
  }, [pdfUrl]);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <>
      <style>{`
        @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Kalam:wght@300;400;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
      `}</style>

      <div className="flex h-screen">
        {/* Sidebar */}
        <div className="w-64 shadow-md" style={{ backgroundColor: '#D9D9D9' }}>
          <div className="p-4">
            <div className="flex items-center mb-6">
              
              <h1 className="font-['Comfortaa'] text-xl font-semibold">Spearmigrations</h1>
            </div>
            <button
              onClick={() => navigate('/')}
              className="w-full py-2 mb-4 font-['Montserrat'] text-white bg-blue-500 rounded hover:bg-blue-600 flex items-center justify-center"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              See All Agencies
            </button>
            <div className="mt-6">
              <div className="flex items-center mb-2">
                <h2 className="text-lg font-semibold font-['Montserrat']">{agencyName}</h2>
              </div>
              <ul className="space-y-2 ml-7">
                <AddClientButton onAddClient={handleAddClient} />
                {clients.map((client) => (
                  <li
                    key={client}
                    onClick={() => handleClientClick(client)}
                    className={`py-2 px-4 hover:bg-gray-200 cursor-pointer rounded font-['Montserrat'] ${
                      selectedClient === client ? 'bg-gray-300' : ''
                    }`}
                  >
                    {client}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-1 p-10 flex justify-center items-center">
          {selectedClient ? (
            <div className="w-[100vh] h-[100%] bg-[#D9D9D9] relative flex flex-col items-center justify-center rounded-xl">
              {pdfUrl ? (
                <div ref={pdfViewerRef} className="w-[95%] h-[90%] bg-white mb-4 overflow-auto rounded-xl filter drop-shadow-lg" />
              ) : (
                <div
                  className="w-[95%] h-[90%] bg-white mb-4 flex items-center justify-center cursor-pointer hover:bg-gray-100 hover:bg-opacity-75 rounded-xl filter drop-shadow-lg"
                  onClick={handleFileUploadClick}
                >
                  <p className="text-xl text-gray-500 font-['Montserrat']">
                    Click to upload a PDF file for {selectedClient}
                  </p>
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <div className="flex space-x-4 w-[95%]">
                <button
                  className={`w-[50%] text-white px-6 py-2 rounded font-['Montserrat'] ${isSnippetMode ? 'bg-red-500' : 'bg-blue-500'}`}
                  onClick={toggleSnippetMode}
                >
                  {isSnippetMode ? 'Exit Snippet Mode' : 'Enter Snippet Mode'}
                </button>
                <button
                  className="bg-black w-[50%] text-white px-6 py-2 rounded font-['Montserrat']"
                  onClick={saveSnippets}
                >
                  Save Snippets
                </button>
              </div>
            </div>
          ) : (
            <p className="text-xl text-center text-gray-600 font-['Montserrat']">
              <b>Select a client to upload files.</b>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default AgencyClients;