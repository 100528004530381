import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import { Alert } from '../../../components/ui/alert';
import { useAuth } from '../../../components/functions/AuthContext';

const API_BASE_URL = 'https://europe-west1-database-bc9ef.cloudfunctions.net/testproductionapi24';

export const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const callbackUrl = searchParams.get('callbackUrl') || '/dashboard';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useAuth();

  useEffect(() => {
    console.log('Cookies on login page load:', document.cookie);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post(`${API_BASE_URL}/verifyLogin`, {
        email,
        password
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      console.log('Login successful:', response.data);
      console.log('Cookies after login:', document.cookie);

      // Store the token in localStorage
      localStorage.setItem('authToken', response.data.token);

      setUser({
        userId: response.data.userId,
        role: response.data.role,
        organizationDomain: response.data.organizationDomain
      });// Set the user in context

      if (response.data.role === 'teamLeader') {
        navigate('/dashboard');
      } else {
        navigate('/onboard');
      }
    } catch (error) {
      console.error('Login error:', error.response?.data?.error || error.message);
      if (error.response?.status === 429) {
        setError('Too many login attempts. Please try again later.');
      } else {
        setError(error.response?.data?.error || 'An error occurred during login. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <style>{`
        @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

        .comfortaa-font {
          font-family: 'Comfortaa', sans-serif;
        }
      `}</style>
      <form onSubmit={onSubmit} className="space-y-12 w-full sm:w-[400px]">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="email">Email</Label>
          <Input
            className="w-full"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            type="email"
          />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="password">Password</Label>
          <Input
            className="w-full"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            type="password"
          />
        </div>

        {error && <Alert>{error}</Alert>}
        <div className="w-full">
          <Button className="w-full" size="lg" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </Button>
        </div>
      </form>
    </>
  );
};
