import React, { useState } from 'react';
import { Check, Calendar, Star } from 'lucide-react';

const TaskCard = ({ taskGroup, header, details, onSave }) => {
  const [editableHeader, setEditableHeader] = useState(header);
  const [editableDetails, setEditableDetails] = useState(Array.isArray(details) ? details : [details]);

  const saveChanges = () => {
    onSave(editableHeader, editableDetails);
  };

  const handleHeaderChange = (event) => {
    const newHeader = event.target.innerText;
    setEditableHeader(newHeader);
    onSave(newHeader, editableDetails);
    console.log('Header changed:', JSON.stringify({ taskGroup, newHeader, details: editableDetails }, null, 2));
  };

  const handleDetailChange = (index, event) => {
    const newDetails = [...editableDetails];
    newDetails[index] = event.target.innerText;
    setEditableDetails(newDetails);
    onSave(editableHeader, newDetails);
    console.log('Details changed:', JSON.stringify({ taskGroup, header: editableHeader, newDetails }, null, 2));
  };

  return (
    <div className="w-full p-4">
      <div className="rounded-lg shadow-md overflow-hidden">
        <div className="bg-[#fdf1a6] p-4">
          <div className="flex items-center text-white">
            <Check className="mr-2" />
            <h2 
              className="text-lg text-yellow-500 font-semibold"
              contentEditable
              suppressContentEditableWarning
              onBlur={handleHeaderChange}
            >
              {editableHeader}
            </h2>
          </div>
        </div>
        
        <div className="bg-white p-4">
          {editableDetails.map((detail, index) => (
            <p 
              key={index} 
              className="mb-4 text-gray-700"
              contentEditable
              suppressContentEditableWarning
              onBlur={(event) => handleDetailChange(index, event)}
            >
              {detail}
            </p>
          ))}
          
          <div className="flex justify-end space-x-4 mt-4">
            <div className="flex items-center text-green-600">
              <Calendar className="mr-1" size={16} />
              <span className="text-sm">21/01/2021</span>
            </div>
            <div className="flex items-center text-red-600">
              <Calendar className="mr-1" size={16} />
              <span className="text-sm">Ongoing</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskCard;