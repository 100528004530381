import React from 'react';
import { Link } from 'react-router-dom';
import SignUpForm from './signUpForm';

const SignUpPage = () => {
  return (
    <>
      <style>{`
        @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

        .comfortaa-font {
          font-family: 'Comfortaa', sans-serif;
        }
      `}</style>
      <div className="h-screen w-screen flex justify-center items-center bg-lop">
        <div className="sm:shadow-xl px-8 pb-8 pt-12 sm:bg-white rounded-xl space-y-12">
          <h1 className="font-['Comfortaa'] text-3xl font-semibold text-center mx-auto">Spearmigrations</h1>
          <p className="font-['Comfortaa'] text-center mx-auto">your partner in data transformation</p>
          <p className="font-['Comfortaa'] text-center mx-auto">Start onboarding - by creating an account with your work email</p>
          <SignUpForm />
          <p className="text-center">
            Already have an account?{' '}
            <Link className="text-indigo-500 hover:underline" to="/login">
              Log In
            </Link>{' '}
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
