
                 
import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Check, Star } from 'lucide-react';
import FilesSelector from '../components/ui/fileDisplay';
import AddClientButton from '../components/functions/addClient';
import styles from '../styles/clientdetails.module.css';
import CategoryCard from '../components/ui/categorycards';
// Then use it like this:
import { fabric } from 'fabric';
import { pdfjsLib3 } from './pdfjs3-init';
import axios from 'axios';
import { Buffer } from 'buffer';
import ClientNamePopup from '../components/ui/ClientPopup';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../auth/firebaseConfig';
import { doc, arrayUnion, serverTimestamp , collection, setDoc, getDoc, updateDoc, addDoc, getDocs } from "firebase/firestore";
//import useJobStatus from '../components/functions/jobStatus';
import TaskCard from '../components/ui/taskCards';
const API_BASE_URL = 'https://europe-west1-database-bc9ef.cloudfunctions.net/testproductionapi24';


const ClientDetails = () => {
  const [agencyName, setAgencyName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isFileSubmitted, setIsFileSubmitted] = useState(false);
  const [pdfPages, setPdfPages] = useState([]);
  const [organizationId, setOrganizationId] = useState('');
  const [fileInfo, setFileInfo] = useState({});
  const memoizedFileInfo = useMemo(() => fileInfo, [fileInfo]);
  const navigate = useNavigate();
  const [isMouseOverSidebar, setIsMouseOverSidebar] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isSnippetMode, setIsSnippetMode] = useState(false);
  const [fabricCanvases, setFabricCanvases] = useState([]);
  const [pdfCanvases, setPdfCanvases] = useState([]);
  const fileInputRef = useRef(null);
  const pdfViewerRef = useRef(null);
  const [fileJobIds, setFileJobIds] = useState({});
  const fabricCanvasesRef = useRef([]);
  const [activeTab, setActiveTab] = useState(null);
  const pdfCanvasesRef = useRef([]);
  const [jobResults, setJobResults] = useState(null);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [newClientName, setNewClientName] = useState('');
  const [isRendering, setIsRendering] = useState(false);
  const [showProcessingPopup, setShowProcessingPopup] = useState(false);
  const [activeTabs, setActiveTabs] = useState([]);
  const [fileSubmissionStatus, setFileSubmissionStatus] = useState({});
  const [workingClientName, setWorkingClientName] = useState('');
  const [currentJobId, setCurrentJobId] = useState(null);
  const mainFileInputRef = useRef(null);
  const [agencyId, setAgencyId] = useState('');
  const popupFileInputRef = useRef(null);
  //const { jobStatus, isCompleted } = useJobStatus(currentJobId);
  

  const handleMainFileUploadClick = () => {
    if (mainFileInputRef.current) {
      mainFileInputRef.current.click();
    }
  };

  const handlePopupFileUploadClick = () => {
    if (popupFileInputRef.current) {
      popupFileInputRef.current.click();
    }
  };

  

  // Generate a unique job ID
  const generateUniqueJobId = () => {
    return uuidv4();
  };


  useEffect(() => {
    console.log('activeTab changed:', activeTab);
    console.log('Current jobResults:', jobResults);

    if (!activeTab) return; // Exit if there's no active tab
  
    const checkJobStatusAndFetchResults = async () => {
      const status = await fetchJobStatusFromFirestore(activeTab);
      if (status === 'completed') {
        // Check if we already have results for this job
        setFileSubmissionStatus(prevStatus => ({
          ...prevStatus,
          [activeTab]: true
        }));
        console.log('Job completed, fetching results for:', activeTab);
        fetchJobResults(activeTab);
        
      } else {
        // Update job status in state
        console.log('Job is not completed:', status);
      }
    };
  
    // Initial check
    checkJobStatusAndFetchResults();
  
    // Set up interval for periodic checks
    const intervalId = setInterval(checkJobStatusAndFetchResults, 5000);
  
    // Clean up interval on unmount or when activeTab changes
    return () => clearInterval(intervalId);
  }, [activeTab, jobResults]);


  const fetchJobStatusFromFirestore = async (jobId) => {
    const userFilesRef = doc(db, 'agencies', agencyId, 'userFiles', 'fileData');
    try {
      const docSnap = await getDoc(userFilesRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log('file document data ', data);
        
        if (data.files && data.files[jobId]) {
          const jobStatus = data.files[jobId].status;
          console.log('Retrieved job status for jobId:', jobId, 'Status:', jobStatus);
          return jobStatus;
        } else {
          console.warn('No data found for jobId:', jobId);
          return null;
        }
      } else {
        console.warn('No document found in Firestore');
        return null;
      }
    } catch (error) {
      console.error('Error fetching job results from Firestore:', error);
      return null;
    }
  };

  {/*useEffect(() => {
    const intervalId = setInterval(() => {
      Object.keys(fileInfo).forEach(jobId => {
        fetchJobStatusFromFirestore(jobId);
      });
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, [fileInfo]);*/}

  const handleTaskSave = (taskGroup, originalHeader, newHeader, newDetails) => {
    if (!jobResults || !activeTab) return;
  
    setJobResults(prevResults => {
      const newResults = JSON.parse(JSON.stringify(prevResults)); // Deep clone
      
      if (!newResults[activeTab] || !newResults[activeTab][taskGroup]) {
        console.error('Invalid jobResults structure:', newResults);
        return prevResults; // Return unchanged if structure is invalid
      }
      
      const groupTasks = newResults[activeTab][taskGroup];
  
      if (typeof groupTasks !== 'object' || groupTasks === null) {
        console.error('groupTasks is not an object:', groupTasks);
        return prevResults; // Return unchanged if groupTasks is invalid
      }
  
      // Find the task with the original header
      if (originalHeader in groupTasks) {
        // Remove the old task
        const taskContent = groupTasks[originalHeader];
        delete groupTasks[originalHeader];
  
        // Add the updated task with potentially new header
        groupTasks[newHeader] = newDetails;
      } else {
        console.warn(`Task with header "${originalHeader}" not found in ${taskGroup}`);
      }
  
      console.log('Updated jobResults:', JSON.stringify(newResults, null, 2));
  
      return newResults;
    });
  };

  const fetchJobResults = async (jobId) => {
    if (!jobId) return;
  
    try {
      const results = await axios.get(`${API_BASE_URL}/getJobResult/${jobId}`);
      console.log('Job results for jobid', jobId, ':', results.data);
  
      setJobResults(prevResults => ({
        ...prevResults,
        [jobId]: { status: 'completed', results: results.data }
      }));
  
      console.log('received job results:', results.data);
    } catch (error) {
      console.error('Error fetching job results:', error);
      setJobResults(prevResults => ({
        ...prevResults,
        [jobId]: { status: 'error', error: error.message }
      }));
    }
  };
  
 {/*} useEffect(() => {
    
      console.log('Job completed, fetching results for:', activeTab);
      fetchJobResults(activeTab);
    
  },);*/}
  
  const handleClose = () => {
    setShowProcessingPopup(false);
  };
  
  const [isAddingNewFile, setIsAddingNewFile] = useState(false);

  const handleAddNewFile = () => {
    setActiveTab(null);
    setPdfUrl(null);
    setSelectedFile(null);
    setIsAddingNewFile(true);
  };

  const ProcessingPopup = ({ selectedFileName, onClose, handleFileChange, popupFileInputRef, uploadPDFAndSnippets, handlePopupFileUploadClick }) => {

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-xl shadow-lg w-96">
          <h2 className="text-2xl font-['Montserrat'] mb-4">Processing Your File</h2>
          <p className="font-['Montserrat'] m2-4"><b>{selectedFileName}</b> was successfully submitted and has begun processing.</p>
          <p className="font-['Montserrat']"><br></br>To process another file, press the <br></br><b>'add new file'</b> button in the <b>sidebar</b><br></br></p>
          <div className="flex space-x-4 w-[100%]">
            
            <button
              className="w-[100%] bg-[#F9F9F9] mt-3 font-['Montserrat'] border border-black px-6 py-2 rounded font-['Montagu_Slab']"
              onClick={onClose}
            >
              Exit
            </button>
            <input
              type="file"
              ref={popupFileInputRef}
              className="hidden"
              onChange={async (e) => {
                const uploadedFileInfo = await handleFileChange(e);
                if (uploadedFileInfo) {
                  await uploadPDFAndSnippets(uploadedFileInfo);
                  console.log('Uploaded File Info:', uploadedFileInfo);
                }
              
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  
  

  const getCurrentJobId = () => {
    if (!currentJobId) {
      console.log('there is currently no jobId - might wanna check your code pal')  
    }
    return currentJobId;
  };
  

  const fetchUserInfo = async () => {
    console.log('fetchUserInfo called');
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${API_BASE_URL}/getUserInfo`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data; // Return the entire response data
    } catch (error) {
      console.error('Error fetching user info:', error);
      return null;
    }
  };
  
  const memoizedFetchUserInfo = useCallback(fetchUserInfo, []);
  
  function generateRandomUserId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
  
  
  const handleAddFirstClient = (e) => {
    e.preventDefault();
    if (newClientName.trim()) {
      handleAddClient(newClientName);
      setNewClientName('');
    }
  };
  
  
  const fetchUserInfoParent = useCallback(async () => {
    console.log('fetchUserInfoParent called');
    try {
      const userInfo = await fetchUserInfo(); // Assume this function exists
      if (userInfo) {
        setAgencyName(userInfo.agencyName);
        setOrganizationId(userInfo.organizationId);
        setAgencyId(userInfo.agencyId);
        return userInfo.agencyId;
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  }, []);

  function useUserInfo() {
    const userInfoRef = useRef(null);
    const fetchingRef = useRef(false);
  
    const fetchUserInfo = useCallback(async () => {
      if (userInfoRef.current) {
        return userInfoRef.current;
      }
  
      if (fetchingRef.current) {
        // If already fetching, wait for it to complete
        while (fetchingRef.current) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        return userInfoRef.current;
      }
  
      console.log('fetchUserInfo called');
      fetchingRef.current = true;
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`${API_BASE_URL}/getUserInfo`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        userInfoRef.current = response.data;
        return userInfoRef.current;
      } catch (error) {
        console.error('Error fetching user info:', error);
        return null;
      } finally {
        fetchingRef.current = false;
      }
    }, []);
  
    return fetchUserInfo;
  }

  const fetchUserFiles = useCallback(async (agencyId) => {
    if (!agencyId) return;
    console.log('fetchUserFiles called with agencyId:', agencyId);
    const userFilesRef = doc(db, 'agencies', agencyId, 'userFiles', 'fileData');
    try {
      const docSnap = await getDoc(userFilesRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setActiveTabs(prevTabs => {
          if (JSON.stringify(prevTabs) !== JSON.stringify(data.activeTabs || [])) {
            return data.activeTabs || [];
          }
          return prevTabs;
        });
        setFileInfo(prevInfo => {
          if (JSON.stringify(prevInfo) !== JSON.stringify(data.files || {})) {
            return data.files || {};
          }
          return prevInfo;
        });
        setActiveTab(prevTab => {
          const newTab = data.activeTab || null;
          if (prevTab !== newTab) {
            return newTab;
          }
          return prevTab;
        });
        if (data.activeTab && data.files[data.activeTab]) {
          setCurrentJobId(prevId => {
            if (prevId !== data.activeTab) {
              return data.activeTab;
            }
            return prevId;
          });
        }
      }
    } catch (error) {
      console.error('Error fetching user files:', error);
    }
  }, [[setActiveTabs, setFileInfo, setActiveTab, setCurrentJobId]]);

  const initializedRef = useRef(false);

  
  
  useEffect(() => {
    const handleMouseMove = (event) => {
      if (event.clientX < 46 || isMouseOverSidebar) {
        setIsSidebarVisible(true);
      } else {
        setIsSidebarVisible(false);
      }
    };
  
    window.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMouseOverSidebar]); // Add isMouseOverSidebar to the dependency array

  useEffect(() => {
    let timeoutId;
  
    const handleMouseMove = (event) => {
      if (event.clientX < 46 || isMouseOverSidebar) {
        setIsSidebarVisible(true);
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      } else {
        timeoutId = setTimeout(() => {
          setIsSidebarVisible(false);
        }, 300); // 300ms delay, adjust as needed
      }
    };
  
    window.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isMouseOverSidebar]);

  const fetchClients = async (AgencyId) => {
    try {
      const clientsRef = collection(db, 'agencies', AgencyId, 'clients');
      const clientsSnapshot = await getDocs(clientsRef);
      const clientsData = [];
      clientsSnapshot.forEach(doc => {
        clientsData.push({ id: doc.id, ...doc.data() });
      });
      
      setClients(clientsData);
      if (clientsData.length === 0) {
        
        setShowPopup(true);
      } 
      else if (clientsData.length > 0 && !selectedClient) {
        setSelectedClient(clientsData[0].id);
      }
      
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };
  
  useEffect(() => {
    const initializeData = async () => {
      if (initializedRef.current) return;
      initializedRef.current = true;

      const fetchedAgencyId = await fetchUserInfoParent();
      if (fetchedAgencyId) {
        await fetchClients(fetchedAgencyId);
        await fetchUserFiles(fetchedAgencyId);
      }
    };

    initializeData();
  }, [fetchUserInfoParent, fetchClients, fetchUserFiles]);


  

  // Helper functions for encryption
    async function generateKey() {
      return await window.crypto.subtle.generateKey(
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt", "decrypt"]
      );
    }
    
    async function encryptFile(file, key) {
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      const fileArrayBuffer = await file.arrayBuffer();
      const encryptedContent = await window.crypto.subtle.encrypt(
        { name: "AES-GCM", iv: iv },
        key,
        fileArrayBuffer
      );
    
      // Extract the authTag from the encrypted content
      const authTag = encryptedContent.slice(-16); // Last 16 bytes are the authTag
      const encryptedData = encryptedContent.slice(0, -16); // The rest is the encrypted content
    
      return { encryptedContent: encryptedData, iv, authTag };
    }
    
    async function exportKey(key) {
      const exportedKey = await window.crypto.subtle.exportKey("raw", key);
      return Buffer.from(exportedKey).toString('base64');
    }
    
    async function encryptFileForKMS(file) {
      const fileBlob = typeof file === 'string' ? await base64ToBlob(file) : file;
      const dek = await generateKey();
      const { encryptedContent, iv, authTag } = await encryptFile(fileBlob, dek);
      const exportedDek = await exportKey(dek);
      const publicKeyPem = await getPublicKeyFromServer(); // Retrieve the public key from KMS
      const encryptedDek = await encryptKeyWithPublicKey(exportedDek, publicKeyPem);
    
      return {
        encryptedContent,
        iv,
        encryptedDek,
        authTag
      };
    }
    

    async function getPublicKeyFromServer() {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`${API_BASE_URL}/getPublicKey`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        let publicKey = response.data.publicKey;
        
        // Clean the public key
        publicKey = publicKey.trim();
        
        console.log('Cleaned Public Key PEM:', publicKey);
        
        return publicKey;
      } catch (error) {
        console.error('Error fetching public key:', error);
        throw error;
      }
    }
    

    async function encryptKeyWithPublicKey(key, publicKeyPem) {
      // Convert PEM to DER
      const pemHeader = "-----BEGIN PUBLIC KEY-----";
      const pemFooter = "-----END PUBLIC KEY-----";
      let pemContents = publicKeyPem.replace(pemHeader, '').replace(pemFooter, '').trim();
    
      console.log('PEM Contents:', pemContents);
    
      try {
        // Base64 decode the PEM contents
        const binaryDerString = atob(pemContents);
    
        // Convert to Uint8Array
        const binaryDer = new Uint8Array(binaryDerString.length);
        for (let i = 0; i < binaryDerString.length; i++) {
          binaryDer[i] = binaryDerString.charCodeAt(i);
        }
    
        // Import the public key
        const importedPublicKey = await window.crypto.subtle.importKey(
          "spki",
          binaryDer,
          {
            name: "RSA-OAEP",
            hash: { name: "SHA-256" },
          },
          false,
          ["encrypt"]
        );
    
        // Decode the base64 key back to raw bytes
        const decodedKey = Uint8Array.from(atob(key), c => c.charCodeAt(0));
    
        // Encrypt the key
        const encryptedKey = await window.crypto.subtle.encrypt(
          {
            name: "RSA-OAEP"
          },
          importedPublicKey,
          decodedKey
        );
    
        // Convert the encrypted key to a Base64 string and return it
        return Buffer.from(encryptedKey).toString('base64');
    
      } catch (error) {
        console.error('Error during encryption:', error);
        throw error;
      }
    };

  // functions for uploading snippets and PDFs
  const uploadPDFAndSnippets = async (fileInfo = null) => {

    if (!fileInfo && (!selectedFile || !selectedClient)) {
      console.error('No file selected or no client selected');
      return;
    }

    setIsLoading(true);
    // Update file status in Firestore
     
    const file = fileInfo ? fileInfo.file : selectedFile;
    const fileName = fileInfo ? fileInfo.fileName : selectedFile.name;
    const jobId = fileInfo ? fileInfo.jobId : activeTab;
    

    // add to filejobids object
    console.log('Using JobId:', jobId, 'for file:', fileName);
    console.log('current filename', fileName)
    const fileIds = [];

    try {
      
      //const userFilesRef = doc(db, 'agencies', agencyId, 'userFiles', 'fileData');

      setJobResults(prevResults => ({
        ...prevResults,
        [fileName]: { status: 'processing', results: null }
      }));
    
      // Upload PDF
      const pdfFilePath = `${organizationId}/${agencyName}/${selectedClient}/${jobId}/${fileName}`;
      const pdfEncrypted = await encryptFileForKMS(file);
      console.log('pdfEncrypted', pdfEncrypted, 'pdfFilePath', pdfFilePath, 'jobId', jobId, 'fileType', file.type)
      const { fileId: pdfFileId } = await uploadFile(pdfEncrypted, pdfFilePath, jobId, file.type);
      
      fileIds.push(pdfFileId);

      // Upload Snippets
      for (let index = 0; index < fabricCanvasesRef.current.length; index++) {
        const canvas = fabricCanvasesRef.current[index];
        const pageSnippets = canvas.getObjects().filter(obj => obj.type === 'rect');
        const scrollTop = pdfViewerRef.current.scrollTop;

        for (let snippetIndex = 0; snippetIndex < pageSnippets.length; snippetIndex++) {
          const snippet = pageSnippets[snippetIndex];
          const snippetCanvas = document.createElement('canvas');
          snippetCanvas.width = snippet.width;
          snippetCanvas.height = snippet.height;
          const snippetContext = snippetCanvas.getContext('2d');
          snippetContext.drawImage(
            pdfCanvasesRef.current[index],
            snippet.left, snippet.top + scrollTop, snippet.width, snippet.height,
            0, 0, snippet.width, snippet.height
          );

          const snippetDataUrl = snippetCanvas.toDataURL("image/png");
          const snippetBlob = dataURItoBlob(snippetDataUrl);
          const snippetFileName = `snippet_page${index + 1}_${snippetIndex + 1}.png`;
          const snippetFilePath = `${agencyName}/${selectedClient}/${jobId}/snippets/${snippetFileName}`;

          //const snippetEncrypted = await encryptFileForKMS(snippetBlob);
          //const { fileId: snippetFileId } = await uploadFile(snippetEncrypted, snippetFilePath, jobId, 'image/png');
          //fileIds.push(snippetFileId);
          
        }
      }

      // Create job document in Firestore
      await createJobDocument(jobId, 'pending', fileIds);
      

      setFileSubmissionStatus(prevStatus => ({
        ...prevStatus,
        [jobId]: true
      }));

      console.log('PDF and snippets uploaded successfully');
      
      // Start processing job
      await startJob(jobId);

    } catch (error) {
      console.error('Upload failed:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const [viewMode, setViewMode] = useState('tasks');
  const renderContent = () => {
    if (!jobResults || !activeTab || !jobResults[activeTab]) {
      return <p className="text-center text-gray-500">No file selected or uploaded yet.</p>;
    }

    const { status, results } = jobResults[activeTab];

    if (status === 'failed') {
      return <p className="text-center text-red-500">Failed to fetch results for this file.</p>;
    }

    if (status !== 'completed' || !results && isFileSubmitted) {
      return <p className="text-center text-gray-500">Processing file...</p>;
    }

    if (viewMode === 'tasks') {
      return Object.entries(results).flatMap(([taskGroup, tasks]) => 
        Object.entries(tasks).map(([header, details]) => (
          <TaskCard 
            key={`${taskGroup}-${header}`}
            taskGroup={taskGroup}
            header={header}
            details={details}
            onSave={(newHeader, newDetails) => handleTaskSave(taskGroup, header, newHeader, newDetails)}
          />
        ))
      );
    } else {
      return Object.entries(results).map(([category, tasks]) => (
        <CategoryCard key={category} category={category} tasks={tasks} />
      ));
    }
  };

    const uploadFile = async (encryptedData, filePath, jobId, fileType) => {
      const { encryptedContent, iv, encryptedDek, authTag } = encryptedData;
      const payload = {
        file: {
          name: filePath.split('/').pop(),
          type: fileType,
          content: Buffer.from(encryptedContent).toString('base64'),
        },
        encryptedDek,
        iv: Buffer.from(iv).toString('base64'),
        authTag: authTag ? Buffer.from(authTag).toString('base64') : undefined,
        jobId: jobId,
        filePath: filePath
      };

      const token = localStorage.getItem('authToken');
      const response = await axios.post(`${API_BASE_URL}/uploadFile`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
        timeout: 60000 
      });

      if (response.status !== 200) {
        throw new Error('File upload failed');
      }

      return { fileId: response.data.fileId, filePath: response.data.filePath };
    };
    
    const saveSnippets = useCallback(async () => {
      if (activeTab && fileInfo[activeTab]) {
        await uploadPDFAndSnippets({
          file: selectedFile,
          fileName: fileInfo[activeTab].fileName,
          jobId: activeTab
        });
      } else {
        console.error('No active tab or file info available');
      }
    }, [activeTab, fileInfo, selectedFile, uploadPDFAndSnippets]);
  
  
    async function base64ToBlob(base64) {
      const binaryData = atob(base64.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
    
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
    
      return new Blob([uint8Array], { type: 'application/octet-stream' });
    }

  const handleAddClient = async (newClientName) => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.post(`${API_BASE_URL}/addClient`, 
        { newClientName, agencyName },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      const { clientId, client } = response.data;
  
      // Update the local state
      setClients(prevClients => [...prevClients, client]);
      console.log('added client:', client);
      // Set the newly created client as the selected client
      setSelectedClient(clientId);
      setShowPopup(false);
    } catch (error) {
      console.error("Error adding client: ", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };
  
  
  
  
  const handleFileUploadClick = () => {
      fileInputRef.current.click();
  };

 /* const createOrUpdateJob = async (jobId, status, files, organization, agency, client) => {
    try {
      const response = await axios.post('https://YOUR_CLOUD_FUNCTION_URL/createOrUpdateJob', {
        jobId: jobId,
        status: status,
        files: files,
        organization: organization,
        agency: agency,
        client: client
      });
      console.log('Job created/updated successfully:', response.data);
    } catch (error) {
      console.error('Error creating/updating job:', error);
    }
  };*/

 /* const handleConfirmation = async () => {
    const jobId = 'unique-job-id';  // Generate or get the job ID
    const status = 'confirmed';
    const files = ['file1.pdf', 'file2.pdf'];  // List of files
    const organization = 'organization-name';
    const agency = 'agency-name';
    const client = 'client-name';
  
    await createOrUpdateJob(jobId, status, files, organization, agency, client);
  };
  */
  // Attach handleConfirmation to your confirmation button
  

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  const createJobDocument = async (jobId, status, fileIds) => {
    try {
      const token = localStorage.getItem('authToken');
      const payload = {
        jobId,
        status,
        fileIds,
        organization: organizationId,
        agencyId: agencyId,
        client: selectedClient
      };
      console.log('Payload for createJobDocument:', payload);
  
      const response = await axios.post(`${API_BASE_URL}/createJobDocument`, payload, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      console.log('Job document created:', response.data);
      setCurrentJobId(jobId);
      return response.data.jobId;
    } catch (error) {
      console.error('Error creating job document:', error);
      throw error;
    }
  };

  /*const uploadFileToGCS = async (filePath, encryptedContent, iv, encryptedDek, authTag, metadata) => {
    try {
      const token = localStorage.getItem('authToken');
      const payload = {
        file: {
          content: Buffer.from(encryptedContent).toString('base64'),
          type: 'image/png',
        },
        filePath,
        metadata,
        encryptedDek,
        iv: Buffer.from(iv).toString('base64'),
        authTag: Buffer.from(authTag).toString('base64'),
      };
  
      const response = await axios.post(`${API_BASE_URL}/uploadFile`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
        timeout: 60000 
      });
  
      return response.data;
    } catch (error) {
      console.error('Error uploading file to GCS:', error);
      throw error;
    }
  };*/

  

  

  const startJob = async (jobId) => {
    try {
        const token = localStorage.getItem('authToken');
        const response = await axios.post(`${API_BASE_URL}/publishJob`, {
            jobId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            timeout: 60000 
        });
        
        console.log('Job Processing started:', response.data);
    } catch (error) {
        console.error('Error starting Job Processing:', error);
    }
  };

 /* const updateJobDocumentWithSnippets = async (jobId, snippets) => {
    try {
      const payload = { jobId, snippets }
      console.log('Payload:', payload);
      const token = localStorage.getItem('authToken');
      const response = await axios.post(
        `${API_BASE_URL}/updateJobWithSnippets`, payload,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        console.log('Job updated successfully with snippets');
      } else {
        throw new Error('Failed to update job with snippets');
      }
    } catch (error) {
      console.error('Error updating job document with snippets:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };*/

  

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('set file', file);
      setSelectedFile(file);
      const fileName = file.name;
  
      
  
      setIsLoading(false);
  
      const newJobId = generateUniqueJobId();
      const fileType = file.type;
      const base64 = await convertToBase64(file);
  
      console.log('New JobId generated:', newJobId, 'for file:', fileName);
  
      const newActiveTabs = [...activeTabs, newJobId];

      setActiveTabs(newActiveTabs);
      setActiveTab(newJobId);
      setCurrentJobId(newJobId);
  
      const newFileInfo = {
        [newJobId]: { 
          fileName, 
          fileType, 
          jobId: newJobId, 
          uploadTime: new Date(), 
          status: 'unsubmitted',
          base64 
        }
      };
  
      const userFilesRef = doc(db, 'agencies', agencyId, 'userFiles', 'fileData');
  
      try {
        await setDoc(userFilesRef, {
          activeTabs: arrayUnion(newJobId),
          activeTab: newJobId,
          files: {
            [newJobId]: newFileInfo[newJobId]
          }
        }, { merge: true });
        
        setFileInfo(prevFileInfo => ({...prevFileInfo, ...newFileInfo}));
        
        setJobResults(prevResults => ({
          ...prevResults,
          [newJobId]: { status: 'pending', results: null }
        }));
  
        setIsAddingNewFile(false);
        setPdfUrl(base64);
        
        renderPDFForSnippeting(base64, screenWidth);
  
        console.log('File info saved to Firestore');
      } catch (error) {
        console.error('Error saving file info to Firestore:', error);
      }
  
      return { file, fileName, jobId: newJobId };
    }
    return null;
  };
  
  
  {/*useEffect(() => {
    if (currentJobId && selectedFileName && !jobResults[selectedFileName]) {
      fetchJobResults(selectedFileName);
    }
  }, [currentJobId, selectedFileName, jobResults]);*/}


  

  /*const handleFileDownload = async (fileId) => {
    
    try {

      const token = localStorage.getItem('authToken'); // Make sure this is set correctly
      const response = await axios.get(`${API_BASE_URL}/getFile/${fileId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob',
       
      });
  
      // Create a blob URL for the decrypted file
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(blob);
  
      // Update the state to display the PDF
      setPdfUrl(url);
      renderPDFForSnippeting(url);
    } catch (error) {
      console.error('File download failed:', error);
      // Show error to the user in the UI
    }
  };

  const handleSnippetDownload = async (snippetId) => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${API_BASE_URL}/getSnippet/${snippetId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'arraybuffer', // Changed from 'blob' to 'arraybuffer'
      });
  
      // Convert ArrayBuffer to Uint8Array
      const decryptedData = new Uint8Array(response.data);
  
      // Create a blob URL for the decrypted snippet
      const blob = new Blob([decryptedData], { type: 'image/png' });
      const url = URL.createObjectURL(blob);
  
      // Return the URL of the decrypted snippet
      return url;
    } catch (error) {
      console.error('Snippet download failed:', error);
      throw error;
    }
  };*/
  
  
  // these two functions will be used when we begin processing tables + images
  const renderPDFForSnippeting = async (base64, screenWidth) => {
    const binaryData = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const pdf = await pdfjsLib3.getDocument({ data: arrayBuffer }).promise;
    const pages = [];
  
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      
      // Calculate the scale based on screenWidth
      const viewportWidth = screenWidth < 1200 ? screenWidth * 0.9 : 1200; // Adjust as needed
      const scale = viewportWidth / page.getViewport({ scale: 1 }).width;
      const viewport = page.getViewport({ scale });
  
      pages.push({
        pageNum,
        viewport,
        renderFunction: (canvas) => {
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          return page.render({ canvasContext: context, viewport });
        }
      });
    }
  
    return pages;
  };

  const toggleSnippetMode = useCallback(() => {
    setIsSnippetMode(prevMode => {
      const newMode = !prevMode;
      fabricCanvasesRef.current.forEach((canvas, index) => {
        if (canvas) {
          if (newMode) {
            addDrawingListeners(canvas);
          } else {
            removeDrawingListeners(canvas);
          }
        }
      });
      return newMode;
    });
  }, []);

  const loadPDF = useCallback(async () => {
    console.log('loadPDF called. activeTab:', activeTab);
    console.log('memoizedFileInfo:', memoizedFileInfo);
  
    if (isRendering) {
      console.log('Already rendering, skipping...');
      return;
    }
  
    setIsRendering(true);
    const currentActiveTab = activeTab; // Capture the current activeTab
  
    if (memoizedFileInfo[currentActiveTab]) {
      const { base64 } = memoizedFileInfo[currentActiveTab];
      console.log('Loading PDF for activeTab:', currentActiveTab);
      console.log('PDF base64 (first 50 chars):', base64.substring(0, 50));
      
      // Check if the activeTab is still the same before rendering
      if (currentActiveTab === activeTab) {
        const pages = await renderPDFForSnippeting(base64, screenWidth);
        setPdfPages(pages);
      } else {
        console.log('activeTab changed during rendering, aborting.');
      }
    } else {
      console.log('No file info found for activeTab:', currentActiveTab);
    }
    
    setIsRendering(false);
  }, [memoizedFileInfo, activeTab, screenWidth, isRendering]);

  useEffect(() => {
    console.log('useEffect for loadPDF triggered. activeTab:', activeTab);
    loadPDF();
  
    return () => {
      setPdfPages([]);
    };
  }, [loadPDF, screenWidth, activeTab]);// Add screenWidth as a dependency

  const PDFPage = React.memo(({ page, onPdfCanvasRendered, onFabricCanvasRendered, isSnippetMode, screenWidth }) => {
    const pdfCanvasRef = useRef(null);
    const renderTaskRef = useRef(null);
  
    useEffect(() => {
      let isMounted = true;
      if (pdfCanvasRef.current && page.renderFunction) {
        const canvas = pdfCanvasRef.current;
        
        renderTaskRef.current = page.renderFunction(canvas);
  
        renderTaskRef.current.promise.then(() => {
          if (isMounted) {
            onPdfCanvasRendered(canvas);
          }
        }).catch((error) => {
          if (error instanceof pdfjsLib3.RenderingCancelledException) {
            console.log('Rendering was cancelled');
          } else {
            console.error('Error rendering PDF:', error);
          }
        });
      }
  
      return () => {
        isMounted = false;
        if (renderTaskRef.current) {
          renderTaskRef.current.cancel();
        }
      };
    }, [page, onPdfCanvasRendered, screenWidth]);
  
    return (
      <div style={{
        position: 'relative',
        width: '100%',
        marginBottom: '1px'
      }}>

        <canvas 
          ref={pdfCanvasRef}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
        />
        <FabricCanvas
          width={page.viewport.width}
          height={page.viewport.height}
          isVisible={isSnippetMode}
          onCanvasReady={onFabricCanvasRendered}
        />
      </div>
    );
  });
    
  const FabricCanvas = React.memo(({ width, height, isVisible, onCanvasReady }) => {
    const canvasRef = useRef(null);
    const fabricInstanceRef = useRef(null);

    useEffect(() => {
      if (canvasRef.current && !fabricInstanceRef.current) {
        fabricInstanceRef.current = new fabric.Canvas(canvasRef.current);
        fabricInstanceRef.current.setDimensions({ width, height });
        if (onCanvasReady) {
          onCanvasReady(fabricInstanceRef.current);
        }
      }

      return () => {
        if (fabricInstanceRef.current) {
          fabricInstanceRef.current.dispose();
          fabricInstanceRef.current = null;
        }
      };
    }, [width, height, onCanvasReady]);

    return (
      <div style={{
        display: isVisible ? 'block' : 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
      }}>
        <canvas ref={canvasRef} style={{
            width: '100%',
            height: '100%'
          }}/>
      </div>
    );
    });

    {/*useEffect(() => {
      if (jobStatus === 'processing') {
        setShowProcessingPopup(true);
      } else {
        setShowProcessingPopup(false);
      }
    }, [jobStatus]);*/}

    const addDrawingListeners = (canvas, pdfViewerRef) => {
      let rect, isDown, origX, origY;
    
      canvas.on('mouse:down', (o) => {
        isDown = true;
        const pointer = canvas.getPointer(o.e);
        const scrollTop = pdfViewerRef.current.scrollTop;
        origX = pointer.x;
        origY = pointer.y + scrollTop;
    
        rect = new fabric.Rect({
          left: origX,
          top: origY - scrollTop,
          width: 0,
          height: 0,
          fill: 'rgba(255,0,0,0.1)',
          stroke: 'red',
          strokeWidth: 2,
          selectable: true,
          evented: true
        });
        canvas.add(rect);
        console.log('Rectangle created:', rect);
      });
    
      canvas.on('mouse:move', (o) => {
        if (!isDown) return;
        const pointer = canvas.getPointer(o.e);
        const scrollTop = pdfViewerRef.current.scrollTop;
    
        rect.set({
          width: Math.abs(origX - pointer.x),
          height: Math.abs(origY - (pointer.y + scrollTop)),
          left: Math.min(origX, pointer.x),
          top: Math.min(origY, pointer.y + scrollTop) - scrollTop
        });
        canvas.renderAll();
      });
    
      canvas.on('mouse:up', () => {
        isDown = false;
        if (rect) {
          rect.setCoords();
          console.log('Rectangle finalized:', rect);
        }
      });
    };
    
    const removeDrawingListeners = (canvas) => {
      canvas.off('mouse:down');
      canvas.off('mouse:move');
      canvas.off('mouse:up');
    };  

    // utility function for capitalising first letter of working client name
    const capitalizeFirstLetter = (name) => {
      if (!name) return name; // Handle empty or undefined names
      return name.charAt(0).toUpperCase() + name.slice(1);
    };
    
    useEffect(() => {
      if (clients && clients.length > 0) {
        const lastClient = clients[clients.length - 1];
        const capitalizedClientName = capitalizeFirstLetter(lastClient.name);
        setWorkingClientName(capitalizedClientName);
        
      }
    }, [clients]);
    

    useEffect(() => {
      const handleResize = () => setScreenWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      return () => {
        fabricCanvasesRef.current.forEach(canvas => {
          if (canvas && typeof canvas.dispose === 'function') {
            try {
              canvas.dispose();
            } catch (error) {
              console.error('Error disposing canvas during cleanup:', error);
            }
          }
        });
        fabricCanvasesRef.current = [];
      };
    }, []);

    useEffect(() => {
      console.log('fileInfo updated:', fileInfo);
    }, [fileInfo]);

    useEffect(() => {
      console.log('Parent: activeTab changed to:', activeTab);
      
    }, [activeTab]);
  
    useEffect(() => {
      const pdfContainer = pdfViewerRef.current;
      if (pdfContainer) {
        pdfContainer.addEventListener('scroll', handleScroll);
        return () => pdfContainer.removeEventListener('scroll', handleScroll);
      }
    }, []);
    
    const handleScroll = () => {
      const scrollTop = pdfViewerRef.current.scrollTop;
      fabricCanvasesRef.current.forEach((canvas) => {
        canvas.wrapperEl.style.top = `${scrollTop}px`;
      });
    };

    useEffect(() => {
      console.log('Snippet mode changed:', isSnippetMode);
    }, [isSnippetMode]);
    
    
    const dataURItoBlob = (dataURI) => {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
    };

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
    
    

    const PDFViewer = React.memo(({ pdfUrl, pdfPages, screenWidth, isSnippetMode }) => {
      const pdfViewerRef = useRef(null);
      const pdfCanvasesRef = useRef([]);
      const fabricCanvasesRef = useRef([]);
    
      return (
        <div ref={pdfViewerRef} className="w-[98%] h-[89%] overflow-x-hidden overflow-y-auto rounded-[5px] filter drop-shadow-lg mb-4">
          {pdfPages && pdfPages.map((page, index) => (
            <PDFPage 
              key={page.pageNum}
              page={page} 
              screenWidth={screenWidth}
              onPdfCanvasRendered={(canvas) => {
                pdfCanvasesRef.current[index] = canvas;
              }}
              onFabricCanvasRendered={(fabricCanvas) => {
                fabricCanvasesRef.current[index] = fabricCanvas;
                if (isSnippetMode) {
                  addDrawingListeners(fabricCanvas, pdfViewerRef);
                }
              }}
              isSnippetMode={isSnippetMode}
              pdfViewerRef={pdfViewerRef}
            />
          ))}
        </div>
      );
    });

    const memoizedPDFViewer = useMemo(() => (
      <PDFViewer
       
        pdfPages={pdfPages}
        screenWidth={screenWidth}
        isSnippetMode={isSnippetMode}
      />
    ), [pdfUrl, pdfPages, screenWidth, isSnippetMode]);

    return (
        <>
          <style>{`
            @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Kalam:wght@300;400;700&family=Montagu+Slab:opsz,wght@16..144,500&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
 `}       </style>
    
          <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            <div 
            className={`fixed top-0 left-0 h-screen w-64 shadow-md transition-transform duration-200 ${isSidebarVisible ? 'translate-x-0' : '-translate-x-full'}`} 
            style={{ backgroundColor: '#ffff', zIndex: 1000 }}
            onMouseEnter={() => setIsMouseOverSidebar(true)}
            onMouseLeave={() => setIsMouseOverSidebar(false)}
          >
            <div className="p-4">
                <div className="flex items-center mb-6">
                  {/*<img src={logo} alt="Custom Logo" className="w-12 h-12 mr-2" />*/}
                  <h2 className="font-['Comfortaa'] text-[22px] font-bold">Spear</h2>
                  
                </div>
                
                <div className="mt-6">
                  <div className="flex items-center mb-2">
                    <h1 className="text-lg text-gray font-['Montserrat']">{agencyName}'s Onboarding</h1>
                  
                  </div>
                  {/* Progress Tracker */}
                  <div className=" progress-tracker mb-4 bg-black w-[28vh] h-[4.5vh] px-3 py-[0.25rem] rounded-[10px] shadow-lg shadow-gray-500/50 flex items-center justify-center">
                  <p style={{ fontSize: '15.5px' }} className="font-['Comfortaa'] text-white text-center text-xl tracking-wide">
                    50% completion
                  </p>
                </div>
                  {/* Files Selector */}
                  <FilesSelector
                  selectedFileName={selectedFileName}
                  
                  
                  setCurrentJobId={setCurrentJobId}
                  jobResults={jobResults}
                  fetchUserInfo={memoizedFetchUserInfo}
                  activeTab={activeTab}
                  activeTabs={activeTabs}
                  setActiveTab={setActiveTab}
                  selectedFile={selectedFile} // Pass the selected file
                  fileInfo={fileInfo}
                  
                  handleAddNewFile={handleAddNewFile}
                  setSelectedFile={setSelectedFile}
                  workingClientName={workingClientName}
                  agencyId={agencyId}
                />
                </div>
              </div>
            </div>
            {clients.length > 0 ? (
            <>
    
            {/* Main content */}
{/* Main content */}
<div className="flex-1 p-10 flex justify-center bg-[#f7f6f2] overflow-x-hidden overflow-y-hidden items-center">
  <div className={`${styles.container} ${screenWidth < 1000 ? 'w-full' : 'w-1/2'}`}>
    <div className={`bg-[#f7f6f2] mt-3 mr-5 relative flex h-[98vh] flex-col shadow-lg shadow-gray-500/50 items-center justify-center rounded-xl px-1 ${
      screenWidth < 1000 ? 'h-screen' : 'min-h-[98vh]'
    }`}>
      {fileInfo[activeTab]?.base64 && !isAddingNewFile ? (
  memoizedPDFViewer
) : (
  <div
    className="w-[98%] h-[89%] mb-3 flex items-center bg-white justify-center cursor-pointer hover:bg-gray-100 hover:bg-opacity-75 rounded-[5px] filter drop-shadow-lg"
    onClick={() => {
      setSelectedFile(null);
      setCurrentJobId(null);
      handleMainFileUploadClick();
    }}
  >
    <p className="text-xl text-gray-500 text-center font-['Montserrat']">
      <b>Click to upload a file for {workingClientName}</b>
    </p>
  </div>
)}
      <input
        type="file"
        ref={mainFileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <div className="flex space-x-4 w-[98%]">
      <button
  className={`w-[100%] px-6 py-2 rounded font-['Montagu_Slab'] ${
    fileSubmissionStatus[activeTab] ? 'bg-gray-400 text-white cursor-not-allowed' : 'bg-black text-white'
  }`}
  onClick={saveSnippets}
  disabled={!pdfUrl || isLoading || fileSubmissionStatus[activeTab]}
>
  {isLoading ? 'Processing...' : fileSubmissionStatus[activeTab] ? 'File Submitted' : 'Submit'}
      </button>
      </div>
    </div>
  </div>

  <div className={`${styles.container} ${screenWidth < 1000 ? 'w-full' : 'w-1/2'}`}>
    <div className={`flex flex-col ${screenWidth < 1000 ? 'h-screen' : 'min-h-[98vh]'}`}>
      <h1 className="text-3xl font-bold mt-3 mb-3 text-center">Preview</h1>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div>
      
      <div className="relative">
  
  <div 
    className={`task-card-container overflow-y-auto border border-gray-200 rounded-lg ${
      screenWidth < 1000 ? 'flex-grow' : 'h-[84vh]'
    }`}
  >
    <div className="sticky top-0 z-10 bg-white mb-4 flex items-center justify-center p-4 border-b border-gray-200">
  <div className="flex space-x-4">
    <button
      className={`px-4 py-2 rounded flex items-center ${viewMode === 'tasks' ? 'bg-[#f7f6f2]' : 'bg-white'}`}
      onClick={() => setViewMode('tasks')}
    >
      <Check className="mr-2" /> Tasks
    </button>
    <button
      className={`px-4 py-2 rounded flex items-center ${viewMode === 'categories' ? 'bg-[#f7f6f2]' : 'bg-white'}`}
      onClick={() => setViewMode('categories')}
    >
      <Star className="mr-2" /> Outcomes
    </button>
  </div>
</div>
    {renderContent()}
  </div>
</div>
    </div>


            <div className="flex space-x-4 w-full mt-2">
              <button
                className="bg-blue-500 text-white px-6 w-full py-2 rounded font-['Montagu_Slab']"
                disabled={!pdfUrl || isLoading}
              >
                      Submit To database
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            </>
            ) : (
              <div className="flex-1 flex items-center justify-center">
                <p className="text-xl text-gray-500 font-['Montserrat']">
                  Add your first client to get started
                </p>
              </div>
            )}
          </div>
          {showProcessingPopup && <ProcessingPopup
          selectedFileName={selectedFileName}
          onClose={handleClose}
          handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          setActiveTab={setActiveTab}
          handlePopupFileUploadClick={handlePopupFileUploadClick}
          uploadPDFAndSnippets={uploadPDFAndSnippets} 
        />}
          {(showPopup || clients.length === 0) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-xl shadow-lg w-96">
            <h2 className="text-2xl font-['Comfortaa'] mb-4">Welcome to Spearmigrations!</h2>
            <p className="font-['Montserrat'] mb-4">Please enter the name of your {clients.length === 0 ? 'first' : 'new'} client:</p>
            <form onSubmit={handleAddFirstClient}>
              <input
                type="text"
                value={newClientName}
                onChange={(e) => setNewClientName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mb-4 font-['Montserrat']"
                placeholder="Client Name"
              />
              <button
                type="submit"
                className="w-full py-2 bg-black text-white rounded hover:bg-blue-600 font-['Montserrat']"
              >
                Add Client
              </button>
            </form>
          </div>
        </div>
        )}

        </>
      );
};
    
export default ClientDetails;