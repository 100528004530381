import React, { useEffect , useState} from 'react';
import { Folder } from 'lucide-react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../auth/firebaseConfig';

const StatusIndicator = ({ status }) => {
  if (status === 'none') return null;
  if (status === 'unsubmitted') return null;
  const color = status === 'processing' ? 'bg-yellow-400' : 'bg-green-400';
  
  return (
    <div className={`w-3 h-3 rounded-full ${color} ml-2`}></div>
  );
};

const FilesSelector = ({ 
  jobResults, 
  activeTabs, 
  activeTab,
  workingClientName,
  setActiveTab,
  setSelectedFile,
  fileInfo,
  setPdfUrl,
  setCurrentJobId,
  handleAddNewFile,
  fetchUserInfo
}) => {
  const [agencyId, setAgencyId] = useState('');

  useEffect(() => {
    const initializeAgencyId = async () => {
      try {
        const userInfo = await fetchUserInfo();
        if (userInfo && userInfo.agencyId) {
          setAgencyId(userInfo.agencyId);
        } else {
          console.error('User info or agencyId not available');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    initializeAgencyId();
  }, [fetchUserInfo]);

  {/*useEffect(() => {
    if (!agencyId) return;
    console.log('id passed', agencyId)
    const userFilesRef = doc(db, 'agencies', agencyId, 'userFiles', 'fileData');
    const unsubscribe = onSnapshot(userFilesRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setActiveTab(data.activeTab || null);
        // You might want to add setActiveTabs and setFileInfo as props if you need to update these in the parent
      }
    });

    return () => unsubscribe();
  }, [agencyId, setActiveTab]);*/}


  const truncateFileName = (fileName) => {
    if (fileName.length > 12 ) {
      return `${fileName.substring(0, 12)}...`;
    }
    return fileName;
  };
    

  const getFileStatus = (jobId) => {
    return fileInfo?.[jobId]?.status || 'none';
  };

  const handleFileClick = (jobId) => {
    console.log('Clicked file with jobId:', jobId);
    setActiveTab(jobId);
    setCurrentJobId(jobId);
    if (fileInfo[jobId]) {
      console.log('Setting PDF URL for jobId:', jobId);
      
      setSelectedFile(fileInfo[jobId]);
    } else {
      console.warn(`No file info found for jobId: ${jobId}`);
    }
  };

  


  return (
    <div className="p-4 font-sans bg-white">
      <div className="flex items-center mb-4">
        <Folder className="mr-2" size={24} />
        <h2 className="text-xl font-semibold">{workingClientName}'s Files</h2>
      </div>
      <button 
        className="w-full mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={handleAddNewFile}
      >
        Add New File
      </button>
      <div className="border-l-2 border-gray-300 ml-3 pl-4">
      {activeTabs.map((jobId, index) => {
      const file = fileInfo[jobId];
      //console.log('fileinfo passed to filesselector:', fileInfo)
      if (!file) {
        console.warn(`No file info found for jobId: ${jobId}`);
        return null; // Skip rendering this item
      }

  //console.log(`Rendering file for jobId: ${jobId}`, file);
  
  return (
    <div
      key={index}
      className={`py-2 px-3 cursor-pointer transition-colors duration-200 flex items-center justify-between ${
        activeTab === jobId ? 'bg-[#f7f6f2]' : 'hover:bg-[#f7f6f2]'
      }`}
      onClick={() => handleFileClick(jobId)}
    >
      <span title={file.fileName}>{truncateFileName(file.fileName)}</span>
      <StatusIndicator status={getFileStatus(jobId)} />
    </div>
  );
})}
</div>
    </div>
  );
};

export default FilesSelector;