import * as React from 'react'
import { cn } from '../../lib/utils'

const Input = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;
  return (
    <input
      className={cn(
        'flex h-10 w-full rounded-md border border-indigo-300 bg-transparent py-2 px-3 text-sm text-black placeholder:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-indigo-700 dark:focus:ring-indigo-400 dark:focus:ring-offset-indigo-900',
        className
      )}
      ref={ref}
      {...otherProps}
    />
  )
})

Input.displayName = 'Input'

export { Input }
