import React from 'react';
import { Check, Star } from 'lucide-react';

const CategoryCard = ({ category, tasks }) => {
  return (
    <div className="w-full p-4">
      <div className="bg-white rounded-lg shadow-md p-4">
      <div className="bg-orange-200 p-4">
        <h2 className="text-xl font-semibold text-orange-500 mb-4">
            <Star className="mr-2"/>{category}</h2>
        </div>
       
        {Object.entries(tasks).map(([header]) => (
          <div key={header} className="mb-2">
            <div className="bg-[#ffe653] p-4">
                
            <h3 className="font-medium">{header}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryCard;