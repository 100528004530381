import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Folder, User } from 'lucide-react';

import axios from 'axios';

const API_BASE_URL = 'https://europe-west1-database-bc9ef.cloudfunctions.net/testproductionapi24';

const Dashboard = () => {
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [showAddAgencyInput, setShowAddAgencyInput] = useState(false);
  const [newAgencyName, setNewAgencyName] = useState('');
  const [newAgencyEmail, setNewAgencyEmail] = useState('');
  const [showAddInviteeInput, setShowAddInviteeInput] = useState(false);
  const [newInviteeEmail, setNewInviteeEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchAgencies();
  }, []);

  const fetchAgencies = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${API_BASE_URL}/getAgencies`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setAgencies(response.data.agencies);
    } catch (error) {
      console.error('Error fetching agencies:', error);
    }
  };

  const handleAgencyClick = (agency) => {
    setSelectedAgency(agency);
    navigate(`/agency/${agency.name}`);
  };

  const handleAddAgency = async () => {
    if (newAgencyName.trim() !== '' && newAgencyEmail.trim() !== '') {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.post(`${API_BASE_URL}/createAgency`, {
          name: newAgencyName.trim(),
          email: newAgencyEmail.trim()
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setAgencies([...agencies, response.data.agency]);
        setNewAgencyName('');
        setNewAgencyEmail('');
        setShowAddAgencyInput(false);
      } catch (error) {
        console.error('Error adding agency:', error);
        alert('Failed to add agency. Please try again.');
      }
    }
  };

  const handleAddInvitee = async () => {
    if (newInviteeEmail.trim() !== '' && selectedAgency) {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.post(`${API_BASE_URL}/addInvitee`, {
          agencyId: selectedAgency.id,
          email: newInviteeEmail.trim()
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const updatedAgencies = agencies.map(agency =>
          agency.id === selectedAgency.id
            ? { ...agency, invitees: [...agency.invitees, response.data.invitee] }
            : agency
        );
        setAgencies(updatedAgencies);
        setNewInviteeEmail('');
        setShowAddInviteeInput(false);
      } catch (error) {
        console.error('Error adding invitee:', error);
        alert('Failed to add invitee. Please try again.');
      }
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-md">
        <div className="p-4">
          <h1 className="text-2xl font-bold">Spear</h1>
        </div>
        <div className="p-4">
          <h2 className="text-lg font-semibold">Everylife's Dashboard</h2>
          <div className="mt-2 bg-black text-white p-2 rounded">
            50% completion
          </div>
        </div>
        <div className="p-4">
          <h3 className="flex items-center text-sm font-semibold">
            Recent Agencies
          </h3>
          <ul className="mt-2 space-y-1">
            {['Coolcare', 'WeCare', 'WhoCares', 'ComfortCare'].map((agency) => (
              <li key={agency} className="text-sm bg-gray-100 p-1 rounded">
                {agency}
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-auto p-4">
          {/*<button className="w-full bg-black text-white p-2 rounded">
            Get In Touch
          </button>*/}
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1 p-8">
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Dashboard</h2>
            <button className="flex items-center border-black text-blue-600">
              Generate detailed report on Coolcare
            </button>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="bg-gray-100 p-6 rounded-lg">
              <div className="text-4xl font-bold">13/113</div>
              <div className="text-sm text-gray-600">clients onboarded</div>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg">
              <div className="text-4xl font-bold">1h 30m</div>
              <div className="text-sm text-gray-600">spent onboarding</div>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg col-span-2">
              <div className="text-lg font-semibold mb-2">
                Line Graph of last few days progress
              </div>
              <div className="text-sm text-gray-600">
                (no. of onboards last few days)
              </div>
              {/* Placeholder for the line graph */}
              <div className="bg-gray-300 h-40 mt-2 rounded"></div>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg">
              <div className="text-4xl font-bold">48</div>
              <div className="text-sm text-gray-600">man-hours saved</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Dashboard;
