import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import { Alert } from '../../../components/ui/alert';

const API_BASE_URL = 'https://europe-west1-database-bc9ef.cloudfunctions.net/testproductionapi24';

const RegistrationPage = () => {
  const [domainName, setDomainName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agencies, setAgencies] = useState([{ name: '', email: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleAgencyChange = (index, field, value) => {
    const newAgencies = [...agencies];
    newAgencies[index][field] = value;
    setAgencies(newAgencies);
  };

  const addAgency = () => {
    setAgencies([...agencies, { name: '', email: '' }]);
  };

  const removeAgency = (index) => {
    const newAgencies = agencies.filter((_, i) => i !== index);
    setAgencies(newAgencies);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
  
    if (!domainName || !adminEmail || !password) {
      setError("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }
  
    if (!adminEmail.endsWith(`@${domainName}`)) {
      setError("Admin email must match the registered domain name.");
      setIsLoading(false);
      return;
    }
  
    const validAgencies = agencies.filter(agency => agency.name.trim() !== '' && agency.email.trim() !== '');
  
    const payload = {
      name: domainName,
      adminEmail,
      password,
      invitees: validAgencies
    };
    console.log('Request payload:', payload);
  
    try {
      const response = await axios.post(`${API_BASE_URL}/registerOrganization`, payload);
  
      console.log(response.data.message);
      navigate('/dashboard'); // Replace '/dashboard' with your desired route
      
      setDomainName('');
      setAdminEmail('');
      setPassword('');
      setAgencies([{ name: '', email: '' }]);
    } catch (error) {
      console.error('Error during registration:', error.response?.data?.error || error.message);
      setError(`Registration failed: ${error.response?.data?.error || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <style>{`
        @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

        .comfortaa-font {
          font-family: 'Comfortaa', sans-serif;
        }
      `}</style>
      <div className="h-screen w-screen flex justify-center items-center bg-lop">
        <div className="sm:shadow-xl px-8 pb-8 pt-12 sm:bg-white rounded-xl space-y-12">
          <h1 className="font-['Comfortaa'] text-3xl font-semibold text-center mx-auto"><p>Welcome To</p> Spearmigrations</h1>
          <p className="font-['Comfortaa'] text-center mx-auto">Register your organisation below</p>
          <form onSubmit={handleRegistration} className="space-y-12 w-full sm:w-[400px]">
            <div className="grid w-full items-center gap-1.5">
              <Label htmlFor="domainName">Domain Name</Label>
              <Input
                className="w-full"
                required
                value={domainName}
                onChange={(e) => setDomainName(e.target.value)}
                id="domainName"
                type="text"
              />
            </div>
            <div className="grid w-full items-center gap-1.5">
              <Label htmlFor="adminEmail">Admin Email</Label>
              <Input
                className="w-full"
                required
                value={adminEmail}
                onChange={(e) => setAdminEmail(e.target.value)}
                id="adminEmail"
                type="email"
              />
            </div>
            <div className="grid w-full items-center gap-1.5">
              <Label htmlFor="password">Password</Label>
              <Input
                className="w-full"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                type="password"
              />
            </div>
            <div className="space-y-4">
              <Label>Agencies (optional)</Label>
              {agencies.map((agency, index) => (
                <div key={index} className="flex space-x-2">
                  <Input
                    className="w-1/2"
                    placeholder="Agency Name"
                    value={agency.name}
                    onChange={(e) => handleAgencyChange(index, 'name', e.target.value)}
                  />
                  <Input
                    className="w-1/2"
                    placeholder="Agency Email"
                    value={agency.email}
                    onChange={(e) => handleAgencyChange(index, 'email', e.target.value)}
                  />
                  <Button type="button" onClick={() => removeAgency(index)}>Remove</Button>
                </div>
              ))}
              <Button type="button" onClick={addAgency}>Add Agency</Button>
            </div>
            {error && <Alert>{error}</Alert>}
            <div className="w-full">
              <Button className="w-full" size="lg" disabled={isLoading}>
                {isLoading ? 'Registering...' : 'Register'}
              </Button>
            </div>
          </form>
          <p className="text-center">
            Already have an account?{' '}
            <Link className="text-indigo-500 hover:underline" to="/login">
              Login
            </Link>{' '}
          </p>
        </div>
      </div>
    </>
  );
};

export default RegistrationPage;