import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import AgencyClients from './pages/AgencyClients';
import ClientDetails from './pages/ClientDetails';
import Demo from './pages/demo';
import LoginPage from './pages/auth/login/page';
import RegistrationPage from './pages/auth/registration/register';
import SignUpPage from './pages/auth/registration/signUpPage';
import { ProtectedRoute } from './components/functions/routeProtection';


const App = () => {
  console.log('App component rendered');

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route element={<ProtectedRoute allowedRoles={['teamLeader', 'agency_user']} />}>
      <Route path="/onboard" element={<ClientDetails />} />
      <Route path="/query" element={<Demo />} />
      </Route>
      
      <Route element={<ProtectedRoute allowedRoles={['teamLeader']} />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
      
     
    </Routes>
  );
};

export default App;
